import { WP_API_BASE } from '../../shared/constants'
import { buildQueryString } from '../../util/string'

const initialState = {
  categories: [],
}

const LOAD_FILTERS = 'LOAD_FILTERS'
const LOAD_FILTERS_SUCCESS = 'LOAD_FILTERS_SUCCESS'

export default (state = initialState, action = {}) => {
  const { type, payload = {} } = action

  switch (type) {
    case LOAD_FILTERS:
      return {
        ...state,
        categories: state.categories,
      }

    case LOAD_FILTERS_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
      }
    default:
      return state
  }
}

export const getFilters = etikett => (dispatch, getState) => {
  dispatch({ type: LOAD_FILTERS })

  if (getState().allFilters.length) return

  const query = buildQueryString({
    etikett,
  })

  return fetch(`${WP_API_BASE}news_filters?${query}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_FILTERS_SUCCESS,
      })
      return payload
    })
  // .catch(error => {
  //   if (process.env.NODE_ENV === 'development') {
  //     console.error(error) //eslint-disable-line
  //   }
  //   dispatch({
  //     error: error,
  //     type: LOAD_FILTERS_FAILURE,
  //   })
  // })
}
