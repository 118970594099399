import React from 'react'
import PropTypes from 'prop-types'

class FiltersAndDescription extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeFilter = this.onChangeFilter.bind(this)
  }

  onChangeFilter(name, value) {
    this.props.onChangeFilter(name, value)
  }

  render() {
    const { sectionTitle, sectionDescription, categories } = this.props

    return (
      <div className="col-12 news-section__header">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            {sectionTitle && (
              <div className="h2 news-section__title">{sectionTitle}</div>
            )}
            <div className="news-section__description">
              {sectionDescription}
            </div>
          </div>

          <div className="col-lg-5 col-md-12 text-right">
            <div className="news-filter">
              <div className="news-filter__title">Filtern nach:</div>
              <ul className="news-filter__list">
                <li className="news-filter__item">
                  <input
                    type="radio"
                    id="all"
                    name="category"
                    value="all"
                    className="filter-item__checkbox"
                    onChange={() => this.onChangeFilter('category', 0)}
                  />
                  <label htmlFor="all">ALLES</label>
                </li>

                {categories.map(cat => (
                  <li key={cat.id} className="news-filter__item">
                    <input
                      type="radio"
                      id={cat.id}
                      name="category"
                      value={cat.id}
                      onChange={() => this.onChangeFilter('category', cat.id)}
                    />
                    <label htmlFor={cat.id}>{cat.title}</label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FiltersAndDescription.propTypes = {
  categories: PropTypes.array,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  onChangeFilter: PropTypes.func,
}

export default FiltersAndDescription
