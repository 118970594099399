import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../scripts/components/Spinner'
import NewsList from './NewsList'
import { loadPosts } from '../../scripts/redux/modules/news'
import { getFilters } from '../../scripts/redux/modules/filters'
//import { updateFilter } from '../../scripts/redux/modules/selectedFilters'
import './styles.scss'
import FiltersAndDescription from '../news_redux/FiltersAndDescription'
import LargeFilters from '../news_redux/LargeFilters'

class NewsRedux extends React.Component {
  constructor(props) {
    super(props)

    this.updateFilter = this.updateFilter.bind(this)
    this.loadMorePosts = this.loadMorePosts.bind(this)
  }

  loadPosts(more = true) {
    const { abbreviation, posttypes, cat } = this.props
    this.props.dispatch(
      loadPosts(abbreviation, posttypes, cat, more).bind(this)
    )
    // return this.props.dispatch(loadPosts().bind(this))
  }

  loadFilters() {
    const { abbreviation, posttypes } = this.props
    return this.props.dispatch(getFilters(abbreviation, posttypes).bind(this))
  }

  updateFilter(name, value) {
    console.log(name + value) //eslint-disable-line
    const { abbreviation, posttypes } = this.props
    this.props.dispatch(
      loadPosts(
        abbreviation,
        posttypes,
        value === 0 ? 'all' : value,
        false
      ).bind(this)
    )
  }

  render() {
    const {
      error,
      posts,
      layout,
      icon,
      filters,
      dataLoaded,
      section_title: sectionTitle,
      section_description: sectionDescription,
      component_id,
      canLoadMore,
    } = this.props

    if (error) {
      return <div>Error</div>
    }

    if (!dataLoaded) {
      return <Spinner />
    }

    const { categories } = filters

    return (
      <div className="row">
        {layout === 'filters_and_description' && (
          <FiltersAndDescription
            categories={categories}
            sectionTitle={sectionTitle}
            sectionDescription={sectionDescription}
            onChangeFilter={this.updateFilter}
          />
        )}
        {layout === 'large_filters' && (
          <LargeFilters
            icon={icon}
            categories={categories}
            sectionTitle={sectionTitle}
            sectionDescription={sectionDescription}
            onChangeFilter={this.updateFilter}
          />
        )}
        {posts && <NewsList posts={posts} component_id={component_id} />}
        {canLoadMore ? (
          <div className="col-12 text-center">
            <button
              className="btn btn-b-light-gray btn-c-blue"
              onClick={this.loadMorePosts}
            >
              mehr Meldungen anzeigen
            </button>
          </div>
        ) : null}
      </div>
    )
  }

  componentDidMount() {
    this.loadPosts()
    this.loadFilters()
  }

  loadMorePosts() {
    this.loadPosts(true)
  }
}

NewsRedux.propTypes = {
  error: PropTypes.bool,
  canLoadMore: PropTypes.bool,
  posts: PropTypes.array,
  filters: PropTypes.array,
  layout: PropTypes.string,
  posttypes: PropTypes.string,
  component_id: PropTypes.string,
  abbreviation: PropTypes.string,
  icon: PropTypes.string,
  section_title: PropTypes.string,
  section_description: PropTypes.string,
  dataLoaded: PropTypes.bool,
  dispatch: PropTypes.func,
  loadPosts: PropTypes.func,
  loadFilters: PropTypes.func,
  page: PropTypes.string,
  cat: PropTypes.string,
}

export default NewsRedux
