import { connect } from 'react-redux'
import Component from './сomponent'
import { filterPosts } from '../../scripts/redux/modules/selectedFilters'

const WithData = connect(
  state => ({
    posts: filterPosts(state),
    filters: state.allFilters,
    selectedFilters: state.selectedFilters,
    error: state.news.error,
    dataLoaded: state.news.isLoaded,
    page: state.news.page,
    cat: state.news.cat,
    canLoadMore: state.news.canLoadMore,
  }),
  dispatch => ({ dispatch })
)(Component)

export default WithData
