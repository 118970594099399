/* Don't forget to add string 'news' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
import { buildQueryString } from '../../util/string'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: [],
  page: 0,
}

const LOAD_POSTS_DATA = 'LOAD_POSTS_DATA'
const LOAD_POSTS_DATA_SUCCESS = 'LOAD_POSTS_DATA_SUCCESS'
const LOAD_POSTS_DATA_FAILURE = 'LOAD_POSTS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_POSTS_DATA:
      return {
        ...state,
        posts: state.posts,
        isLoading: true,
        canLoadMore: true,
        cat: null,
      }

    case LOAD_POSTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: payload.posts,
        page: payload.page,
        cat: payload.cat,
        canLoadMore: payload.canLoadMore,
      }

    case LOAD_POSTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        canLoadMore: true,
      }

    default:
      return state
  }
}

export const loadPosts = (etikett, posttypes, cat, more) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_POSTS_DATA })

  const query = buildQueryString({
    paged: getState().news.page,
    etikett,
    posttypes,
    cat: cat ? cat : getState().news.cat,
    more,
  })

  return fetch(`${WP_API_BASE}news?${query}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_POSTS_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_POSTS_DATA_FAILURE,
      })
    })
}
