function isScrolledIntoView(elem) {
  var $elem = $(elem)
  var $window = $(window)

  var docViewTop = $window.scrollTop()
  var docViewBottom = docViewTop + $window.height()

  var elemTop = $elem.offset().top
  var elemBottom = elemTop + $elem.height()

  return elemBottom <= docViewBottom && elemTop >= docViewTop
}

function counter($counter) {
  if (!$counter.hasClass('counted')) {
    $counter.addClass('counted')
    $('.counter-number').each(function() {
      $(this)
        .prop('Counter', $(this).data('start'))
        .animate(
          {
            Counter: $(this).data('end'),
          },
          {
            duration: 2000,
            easing: 'swing',
            step: function() {
              var num = Math.ceil(this.Counter).toString()
              if (Number(num) > 999) {
                while (/(\d+)(\d{3})/.test(num)) {
                  num = num.replace(/(\d+)(\d{3})/, '$1' + '.' + '$2')
                }
              }
              $(this).text(num)
            },
          }
        )
    })
  }
}

$('body').scroll(function() {
  $('.counter').each(function() {
    if (isScrolledIntoView($(this))) {
      counter($(this))
    }
  })
})

$(window).load(() => {
  $('.counter').each(function() {
    if (isScrolledIntoView($(this))) {
      counter($(this))
    }
  })
})
