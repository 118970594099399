import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_termine()
})

//event handler info container
function dhsv_vc_termine() {
  $('.dhsv_vc_termine .termin .info').on('click tap', function() {
    var e = $(this)
    var parent = e.parent()
    var content = e.next()
    if (parent.hasClass('active')) {
      content.animate(
        {
          height: 0,
        },
        500
      )
      parent.removeClass('active')
    } else {
      content.animate(
        {
          height: content.get(0).scrollHeight,
        },
        500,
        function() {
          content.height('auto')
        }
      )
      parent.addClass('active')
    }
  })

  //event handler info container
  $('.dhsv_vc_termine .termin span.arrow').on('click tap', function() {
    var e = $(this)
    var parent = e.parent()
    var content = e.prev()
    if (parent.hasClass('active')) {
      content.animate(
        {
          height: 0,
        },
        500
      )
      parent.removeClass('active')
    } else {
      content.animate(
        {
          height: content.get(0).scrollHeight,
        },
        500,
        function() {
          content.height('auto')
        }
      )
      parent.addClass('active')
    }
  })
}
