import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_timelineteaser()
})

function dhsv_vc_timelineteaser() {
  $('.dhsv_vc_timelineteaser').each(function(index, timelineteaser) {
    $('.timelineteaser__show-more').on('click', function() {
      $(this).toggleClass('active')
      $('.timelineteaser__wrapper', timelineteaser).toggleClass('active')
      $('.timelineteaser__spoiler').slideToggle({
        start: function() {
          $(this).css({
            display: 'flex',
          })
        },
      })
    })
  })
}
