import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_recent_posts()
})

function dhsv_vc_recent_posts() {
  $('.dhsv_vc_recent_posts').each(() => {
    $('.recent-post__container', this).hover(
      function() {
        $('.recent-post__excerpt', this)
          .stop()
          .slideDown(300)
      },
      function() {
        $('.recent-post__excerpt', this)
          .stop()
          .slideUp(300)
      }
    )
  })
}
