import React from 'react'
import PropTypes from 'prop-types'

const NewsListItem = ({ item }) => {
  return (
    <div className="col-md-6 col-lg-4">
      <div className="news-section__item">
        <div className="item__heading">
          <div className="item__date">{item.date}</div>
          <div className="item__category">{item.label}</div>
        </div>

        <a href={item.permalink} className="item__content readmore">
          <div
            className="item__image"
            style={{ backgroundImage: 'url(' + item.image + ')' }}
          />

          <div className="item__readmore-wrapper">
            <div className="item__title-wrapper">
              <h3
                className="h5 item__title"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <div className="item__description">{item.excerpt}</div>
            </div>

            <span className="arrow-readmore {{$class}}">
              <span className="readmore" />
              <span className="arrow-right" />
            </span>
          </div>
        </a>
      </div>
    </div>
  )
}

NewsListItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default NewsListItem
