import 'jquery-match-height/dist/jquery.matchHeight-min'

function formsHandler() {
  $('input,textarea', '.gfield').each(function() {
    $(this).attr('placeholder', '')
    if (
      !$(this)
        .closest('.gfield')
        .find('.gfield_label')
        .hasClass('active')
    ) {
      if ($(this).val()) {
        $(this)
          .closest('.gfield')
          .find('.gfield_label')
          .addClass('active')
      }
      $(this).on('focusin', function() {
        $(this)
          .closest('.gfield')
          .find('.gfield_label')
          .addClass('active')
      })

      $(this).on('focusout', function() {
        if (!$(this).val()) {
          $(this)
            .closest('.gfield')
            .find('.gfield_label')
            .removeClass('active')
        }
      })
    }
  })

  $('body').on('change', 'select', function() {
    let select = $(this)
    if ($(this).val()) {
      select.addClass('active')
    } else {
      select.removeClass('active')
    }
  })

  $('.gform_drop_area').each(function() {
    let droparea = $(this)
    let description = droparea.closest('.gfield').find('.gfield_description')
    description.detach()
    description.appendTo(droparea)
  })

  $('.gform_drop_area')
    .on('dragenter dragover', function(e) {
      e.preventDefault()
      e.stopPropagation()
      $(this)
        .closest('.gfield')
        .addClass('dropped')
    })
    .on('drop dragleave ', function(e) {
      e.preventDefault()
      e.stopPropagation()
      $(this)
        .closest('.gfield')
        .removeClass('dropped')
    })
}

export default {
  init() {
    formsHandler()

    $(document).bind('gform_post_render', function() {
      formsHandler()
    })

    // JavaScript to be fired on all pages
    // VC full-width row
    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })
        if (!$(this).attr('data-vc-stretch-content')) {
          let padding =
            (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
          $(this).css({
            'padding-left': padding,
            'padding-right': padding,
          })
        }
      })
    }

    // Toggler for accordion, dropdown & spoiler
    function accordionToggler(
      toggler,
      handled = $(toggler.data('target')),
      slideSpeed = 300
    ) {
      toggler.click(function() {
        $(this)
          .toggleClass('active')
          .closest('.nav-link__wrapper')
          .addClass('super')
          .next(handled)
          .slideToggle(slideSpeed)
        $(this)
          .closest('.menu-item')
          .siblings('.menu-item')
          .find('.accordion-toggle')
          .removeClass('active')
          .end()
          .find('.submenu')
          .slideUp()
      })
    }

    accordionToggler($('.accordion-toggle'), $('.submenu'))

    // Spoiler for heading section on single content page
    // function serviceHeadingInfoContentSpoiler() {
    //   const $headingInfo = $('.service-heading-info')
    //
    //   $headingInfo.each(function() {
    //     const $content = $('.service-heading-info__content', $headingInfo)
    //     const defaultHeight = $content.height() // get default height
    //     const autoHeight = $content.css('height', 'auto').height() // get original height
    //
    //     $content.height(defaultHeight) // set default height
    //
    //     function animateHeight($content, height) {
    //       $content.animate(
    //         {
    //           height: height,
    //         },
    //         500
    //       )
    //       toggleState = !toggleState
    //     }
    //
    //     let toggleState = false
    //
    //     $('.js-service-heading-spoiler-toggler', $headingInfo).on(
    //       'click',
    //       function() {
    //         if (!toggleState) {
    //           animateHeight($content, autoHeight, toggleState)
    //         } else {
    //           animateHeight($content, defaultHeight, toggleState)
    //         }
    //         $(this).toggleClass('active')
    //       }
    //     )
    //   })
    // }

    //serviceHeadingInfoContentSpoiler()

    // make search input empty on press 'ESC'
    $(document).keyup(e => {
      if (e.keyCode === 27) $('.search-form__input').val('')
    })

    // make counter's element parent section z-index higher previous section
    $('.dhsv_vc_counter.push_up')
      .closest('section')
      .css('zIndex', 3)

    // set header background height
    // const bannerHeight = $('.banner').outerHeight()
    // $('.banner__bg').css('maxHeight', bannerHeight)

    const menuHeight = $('.banner__top').outerHeight()
    $('.banner').css('padding-top', menuHeight)

    // youtube video
    $(document).on('click', '.js-video-poster', function(e) {
      e.preventDefault()
      const wrapper = $(this).closest('.js-videoWrapper')
      videoPlay(wrapper)
    })

    // play video & hide poster
    function videoPlay(wrapper) {
      const iframe = wrapper.find('iframe')
      wrapper.addClass('active')
      iframe.attr('src', $('iframe', this).attr('src') + '&autoplay=1')
    }

    resizeFullWidthRow()

    //smooth_scroll
    function smooth_scroll(link, e) {
      if (e) {
        e.preventDefault()
        if (e.isTrigger) {
          return false
        }
      }
      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }
      if (hash) {
        $('html, body').animate(
          {
            scrollTop: $(hash).offset().top,
          },
          600
        )
        history.replaceState(null, null, hash)
      }
    }

    $(document).on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-vc-tabs],[role="tab"])',
      function(e) {
        smooth_scroll($(this).attr('href'), e)
      }
    )
    if (window.location.hash) {
      smooth_scroll(window.location.hash)
    }

    function throttled(delay, fn) {
      let lastCall = 0
      return function(...args) {
        const now = new Date().getTime()
        if (now - lastCall < delay) {
          return
        }
        lastCall = now
        return fn(...args)
      }
    }

    // CALL FUNCTIONS
    init_sticky_animation()

    // DECLARE FUNCTIONS
    function init_sticky_animation() {
      var lastScrollTop = 0
      var scrollTop = undefined
      var body = $('body')
      var distance = $('.banner__top').offset().top

      function handleScroll() {
        scrollTop = $(window).scrollTop()

        if (scrollTop <= distance) {
          body.removeClass('sticky-nav')
        } else {
          body.addClass('sticky-nav')
        }

        lastScrollTop
      }

      handleScroll()
      $(window).on('scroll', throttled(10, handleScroll))
    }

    $(window).on('resize', function() {
      resizeFullWidthRow()
    })

    $(document).ready(function() {
      $('.matchHeight').matchHeight()

      if ($('.ginput_container_select').length) {
        $('.ginput_container_select')
          .parent('.gfield')
          .addClass('hide_label')
      }

      //menu button

      $('.menu-button').click(function() {
        $('.stick').toggleClass(function() {
          return $(this).is('.open, .close') ? 'open close' : 'open'
        })

        $('.navbar-collapse').slideToggle()
      })
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
