var brekpointSM = 767
var brekpointXS = 576
var customMobileSpace = function() {
  if ($('.vc_empty_space').length) {
    $('.vc_empty_space').each(function() {
      var mobileSpaceXS = $(this).data('mobile-space')
      var mobileSpaceSM = $(this).data('mobile-space-bigger')
      if (mobileSpaceXS >= 0 || mobileSpaceXS) {
        if (!$(this).hasClass('sized') && $(window).width() <= brekpointXS) {
          $(this)
            .css('maxHeight', mobileSpaceXS)
            .addClass('sized')
        } else if (
          $(this).hasClass('sized') &&
          $(window).width() > brekpointXS
        ) {
          $(this)
            .css('maxHeight', '')
            .removeClass('sized sizedSM')
        }
      }
      if (mobileSpaceSM >= 0 || mobileSpaceSM) {
        if (
          !$(this).hasClass('sizedSM') &&
          $(window).width() <= brekpointSM &&
          $(window).width() > brekpointXS
        ) {
          $(this)
            .css('maxHeight', mobileSpaceSM)
            .addClass('sizedSM')
        } else if (
          $(this).hasClass('sizedSM') &&
          $(window).width() > brekpointSM
        ) {
          $(this)
            .css('maxHeight', '')
            .removeClass('sizedSM')
        }
      }
    })
  }
}

$(document).ready(function() {
  customMobileSpace()
})

$(window).resize(function() {
  customMobileSpace()
})
