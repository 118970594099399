import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import NewsListItem from './NewsListItem'
import LoadDomHandler from './DomHandler'

class NewsList extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    LoadDomHandler(this.props.component_id)
  }

  componentDidUpdate() {
    LoadDomHandler(this.props.component_id)
  }

  render() {
    const { posts } = this.props

    return (
      <Fragment>
        {posts.map(item => <NewsListItem item={item} key={item.id} />)}
      </Fragment>
    )
  }
}

NewsList.propTypes = {
  posts: PropTypes.array,
  filters: PropTypes.object,
  component_id: PropTypes.string,
}

export default NewsList
