import $ from 'jquery'

export default (function() {
  return function(component_id) {
    $('.item__content.readmore', '#' + component_id).each(function(index, el) {
      $(el)
        .mouseover(function() {
          $('.item__description', $(el))
            .stop(true, false)
            .slideDown(300)
        })
        .mouseleave(function() {
          setTimeout(
            function() {
              $('.item__description', $(el))
                .stop(true, false)
                .slideUp(300)
            }.bind(this),
            100
          )
        })
    })
  }
})()
