$('.md-trigger').click(function() {
  $('.md-modal').addClass('md-show')
  $('.pop_up').addClass('open_pop')
})

$('.close_pop').click(function() {
  $('.md-modal').removeClass('md-show')
  $('.pop_up').removeClass('open_pop')
})

$('.pop_up').click(function() {
  $('.md-modal').removeClass('md-show')
  $('.pop_up').removeClass('open_pop')
})
