import React from 'react'
import PropTypes from 'prop-types'

class LargeFilters extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeFilter = this.onChangeFilter.bind(this)
  }

  onChangeFilter(fType, fValue, reset) {
    this.props.onChangeFilter(fType, fValue, reset)
  }

  render() {
    const { sectionTitle, categories, icon } = this.props

    return (
      <div className="col-lg-8 col-md-12">
        <div className="news-section__large-filters">
          <div className="news-section__title-wrapper">
            {sectionTitle && (
              <div
                className="h2 news-section__title"
                dangerouslySetInnerHTML={{ __html: sectionTitle }}
              />
            )}

            {icon && (
              <div
                className="icon"
                style={{ backgroundImage: `url(${icon})` }}
              />
            )}
          </div>

          <div className="news-filter">
            <div className="news-filter__title">Filtern nach:</div>
            <ul className="news-filter__list">
              <li className="news-filter__item">
                <input
                  type="radio"
                  id="all"
                  name="category"
                  value="all"
                  className="filter-item__checkbox"
                  onChange={() => this.onChangeFilter('category', 0, true)}
                />
                <label htmlFor="all">ALLES</label>
              </li>

              {categories.map(cat => (
                <li key={cat.id} className="news-filter__item">
                  <input
                    type="radio"
                    id={cat.id}
                    name="category"
                    value={cat.id}
                    onChange={() => this.onChangeFilter('category', cat.id)}
                  />
                  <label htmlFor={cat.id}>{cat.title}</label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

LargeFilters.propTypes = {
  categories: PropTypes.array,
  icon: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  onChangeFilter: PropTypes.func,
}

export default LargeFilters
