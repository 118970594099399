import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_organization_structure()
})

function dhsv_vc_organization_structure() {
  $('.dhsv_vc_organization_structure').each((i, el) => {
    const $el = $(el)
    const $jsOrganigramPopup = $('.js-organigram-popup', $el)
    const $jsPopupClose = $('.js-organigram-popup-close', $el)

    $('.js-organigram-popup-toggler', $el).each((index, toggler) => {
      const $toggler = $(toggler)
      $toggler.on('click', e => {
        $el.addClass('overlaid')
        $toggler.addClass('active')
        if ($toggler.hasClass('top_button')) {
          $jsOrganigramPopup.css(
            'top',
            $toggler.offset().top -
              $el.offset().top +
              $toggler.outerHeight(true, true) +
              33
          )
        } else if ($toggler.hasClass('akb')) {
          setTimeout(function() {
            var modalHeight = $jsOrganigramPopup.outerHeight(true, true)
            $jsOrganigramPopup.css(
              'top',
              $toggler.offset().top - $el.offset().top + 30 - modalHeight
            )
          }, 100)
        }
        $jsOrganigramPopup.addClass('active')
        //$('.popup__title', $jsOrganigramPopup).text($toggler.data('title'))
        $('.popup__description', $jsOrganigramPopup).text(
          $toggler.data('description')
        )
        e.stopPropagation()
      })
    })

    $(window).click(function() {
      closePopup()
    })

    $jsPopupClose.on('click', () => {
      closePopup()
    })

    $jsOrganigramPopup.click(function(e) {
      e.stopPropagation()
    })

    const closePopup = () => {
      $el.removeClass('overlaid')
      $jsOrganigramPopup.removeClass('active')
      //$('.popup__title', $jsOrganigramPopup).text('')
      $('.popup__description', $jsOrganigramPopup).text('')
      $('.js-organigram-popup-toggler').removeClass('active')
    }
  })
}
