import * as d3 from 'd3'

const components = $('.pie_chart')

components.each(function(index, el) {
  d3Chart(d3, el)
})

function d3Chart(d3, el) {
  const componentId = $(el).attr('id')
  const dataset = $('.chart-container', `#${componentId}`).data('dataset')

  const pie = d3
    .pie()
    .value(function(d) {
      return d.percent
    })
    .sort(null)
    .padAngle(0.1)

  // const width = $('.chart-container__wrapper').width()
  const width = 660
  const height = width
  const radius = width / 2
  const color = 'transparent'
  const color2 = '#4d7b9b'

  const arc1 = d3
    .arc()
    .innerRadius(radius - 60)
    .outerRadius(radius)

  const arc2 = d3
    .arc()
    .innerRadius(radius - 150)
    .outerRadius(radius - 60)

  const svg = d3
    .select(`#${componentId} .chart-container`)
    .append('svg')
    .attr(
      'viewBox',
      -width / 2 + ' ' + -height / 2 + ' ' + width + ' ' + height
    )
    .attr('preserveAspectRatio', 'xMinYMin')
    .attr('class', 'shadow')

  const svg1 = svg.append('g')
  const svg2 = svg.append('g')

  const path1 = svg1
    .selectAll('path')
    .data(pie(dataset))
    .enter()
    .append('path')
    .attr('d', arc1)
    .attr('fill', color)

  path1
    .transition()
    .duration(1000)
    .attrTween('d', function(d) {
      const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d)
      return function(t) {
        return arc1(interpolate(t))
      }
    })

  const path2 = svg2
    .selectAll('path')
    .data(pie(dataset))
    .enter()
    .append('path')
    .attr('d', arc2)
    .attr('fill', color2)
    .attr('data-service-id', function(d) {
      return componentId + d.data.name
    })

  path2
    .transition()
    .duration(1000)
    .attrTween('d', function(d) {
      const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d)
      return function(t) {
        return arc2(interpolate(t))
      }
    })

  const restOfTheData = function() {
    svg1
      .selectAll('text')
      .data(pie(dataset))
      .enter()
      .append('text')
      .transition()
      .duration(0)
      .attr('transform', function(d) {
        return 'translate(' + arc1.centroid(d) + ')'
      })
      .attr('dy', '.4em')
      .attr('text-anchor', 'middle')
      .text(function(d) {
        return d.data.name
      })
      .style('fill', '#fff')
      .style('font-size', '22px')
      .style('font-weight', '700')

    svg2
      .selectAll('text')
      .data(pie(dataset))
      .enter()
      .append('text')
      .transition()
      .duration(0)
      .attr('transform', function(d) {
        return 'translate(' + arc2.centroid(d) + ')'
      })
      .attr('dy', '.4em')
      .attr('text-anchor', 'middle')
      .text(function(d) {
        return d.data.percent + '%'
      })
      .attr('data-service-id', function(d) {
        return componentId + d.data.name
      })
      .style('fill', '#fff')
      .style('font-size', '35px')
      .style('font-weight', '700')
  }

  setTimeout(restOfTheData, 1000)

  const services = $('.pie_chart__services .service', $(`#${componentId}`))

  // $('.shadow', $(`#${componentId}`))
  //   .find('path[data-service-id]:first')
  //   .addClass('active')
  //
  // $('.shadow', $(`#${componentId}`))
  //   .find(`text[data-service-id='${$(this).data('service-id')}']`)
  //   .addClass('active')

  services.each(function(index, el) {
    $(el).hover(
      function() {
        $('[data-service-id]', $(`#${componentId}`)).removeClass('active')
        $(this, $(`#${componentId}`)).addClass('active')
        $(
          `[data-service-id='${$(this).data('service-id')}']`,
          $(`#${componentId}`)
        ).addClass('active')
      }
      // function() {
      //   $('[data-service-id]', chartContainer).removeClass('active')
      // }
    )
  })
}
