import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import news from './news'
import selectedFilters from './selectedFilters'
import allFilters from './filters'

const appReducer = combineReducers({
  browser,
  location: router,
  news,
  selectedFilters,
  allFilters,
})

export default appReducer
